(function ($) {
  $(function () {



    /*
     * Sidebar location
     */

    $('.icon-sidebar').on('click', function () {
      $('.sidebar-contact').toggleClass('active');
    });

    /*
     * Search
     */
    $('.show-search-icon').on('click', function () {
      $('.search-form').toggleClass('active');
    });

    $('.search-form .search-field').focus(function () {
      $('.search-form').css('opacity', '1');
      $(this).css('border', '2px solid red');
    });

    $('.search-form .search-field').blur(function () {
      $('.search-form').css('opacity', '10');
      $(this).css('border', '');
    });


    /* 
     * Navbar fixed 
     */
    $(window).on('scroll', function () {
      if ($(this).scrollTop() > 1) {
        $('.site-header').addClass('navbar-fixed-top');
      } else {
        $('.site-header').removeClass('navbar-fixed-top');
      }
    });

    /*
     * Toggle burger menu on mobile
     */
    $('.menu-btn-burger').on('click', function () {
      $('#nav-icon').toggleClass('open-custom-menu');
      // $('body').toggleClass('fixedPosition');
      $(".mobile-menu").toggleClass('sliding-open');
    });

    /* 
     * Owl carousel
     */
    $('.owl-team').owlCarousel({
      margin: 20,
      items: 3,
      loop: true,
      autoplay: true,
      autoplayTimeout: 4500,
      nav: true,
      dots: false,
      animateOut: 'fadeOut',
      navText: ["<i class='icon-angle-left'></i>", "<i class='icon-angle-right'></i>"],
      // navContainer: '#customNav',
      responsive: {
        0: {
          items: 1
        },
        700: {
          items: 2,
          margin: 0,
        },
        1200: {
          items: 3,
        }
      }
    });

    $('.owl-service').owlCarousel({
      margin: 20,
      items: 4,
      loop: true,
      autoplay: false,
      autoplayTimeout: 4500,
      nav: true,
      dots: false,
      animateOut: 'fadeOut',
      navText: ["<i class='icon-angle-left'></i>", "<i class='icon-angle-right'></i>"],
      // navContainer: '#customNav',
      responsive: {
        0: {
          items: 1
        },
        700: {
          items: 2,
          margin: 0,
        },
        1200: {
          items: 4,
        }
      }
    });

    $('.owl-testimonial').owlCarousel({
      margin: 20,
      items: 1,
      loop: true,
      autoplay: true,
      // autoplayTimeout: 4500,
      nav: false,
      dots: false,
      mouseDrag: false,
      // animateOut: 'fadeOut',
      navText: ["<i class='icon-angle-left'></i>", "<i class='icon-angle-right'></i>"],
      // navContainer: '#customNav', 
    });


    /*
     * Video modal 
     */
    $("body").on("click", "#play-video-modal", function (e) {
      e.preventDefault();
      $("#video-modal").modal("show");
      $("#video-modal iframe")[0].src += "?autoplay=1";
    });

    $("#video-modal").on('hidden.bs.modal', function (e) {
      var videoUrl = $("#video-modal iframe")[0].src;
      videoUrl = videoUrl.replace("?autoplay=1", "");
      $("#video-modal iframe")[0].src = videoUrl;
    });

    /*
     * Open modal
     */

    $('#open-modal').on('click', function (e) {
      e.preventDefault();
      $('#modal').modal('show');
    });

    /*
     * Youtube lazy loading
     */
    $('#play-video-btn').on('click', function (e) {
      e.preventDefault();
      $('#youtubeModal').modal('show');
    });

    $("#youtubeModal").on('hidden.bs.modal', function (e) {
      $("#youtubeModal iframe").attr("src", $("#youtubeModal iframe").attr("src"));
    });



    $('#play-video-btn').on('click', function () {
      var tag = document.createElement('script');
      tag.src = "https://www.youtube.com/iframe_api";
      document.body.appendChild(tag);
    })

    window.onYouTubeIframeAPIReady = function () {
      var videoModules = document.querySelectorAll('.video');
      videoModules = Array.prototype.slice.call(videoModules);
      videoModules.forEach(initializeVideoModule);
    }

    function initializeVideoModule(videoModule) {
      var player = new YT.Player(videoModule.querySelector('.video-placeholder'), {
        videoId: videoModule.dataset.videoId,
        events: {
          onStateChange: function (event) {
            var isEnded = event.data === YT.PlayerState.ENDED;
            videoModule.classList[isEnded ? 'remove' : 'add']('playing');
            if (isEnded) {
              player.destroy();
              videoModule.querySelector('.video-layer').innerHTML = (
                '<div class="video-placeholder"></div>'
              );
              initializeVideoModule(videoModule);
            }
          }
        }
      });
    }

    /*
     * Scroll up button
     */
    $(window).scroll(function () {
      if ($(this).scrollTop() > 150) {
        $('.scroll-to-top').addClass('show');
      } else {
        $('.scroll-to-top').removeClass('show');
        $('.scroll-to-top').addClass('animated bounceOutDown');
        $('.scroll-to-top').removeClass('animated bounceOutUp');
        $('.scroll-to-top i').css('transform', 'rotateY(0) scale(1)');
      }
    });

    $('.scroll-to-top').on("click", function () {
      $('.scroll-to-top').addClass('animated bounceOutUp');
      $('.scroll-to-top i').css('transform', 'rotateY(180deg) scale(1.2)');
      $("html, body").animate({
        scrollTop: 0
      }, 1000);
      return false;
    });

    /*
     * Lightbox gallery
     */
    $('.gallery-images').lightGallery({
      selector: '.gallery-item',
      share: true,
      download: true,
      rotate: false,
      showThumbByDefault: true,
      loop: false,
      slideEndAnimatoin: false,
      thumbnail: true,
      allowMediaOverlap: true,
      toggleThumb: true,
      youtubePlayerParams: {
        loadYoutubeThumbnail: true,
        youtubeThumbSize: 'default',
        modestbranding: 1,
        showinfo: 0,
        rel: 0
      }
    });

    // init Isotope
    $(window).bind("load", function () {
      var $grid = $('.service-grid-content').isotope({
        itemSelector: '.element-item',
        layoutMode: 'fitRows',
        stamp: '.item--static',
        percentPosition: true,
        resizesContainer: true,
        resizable: true,
      });

      $('.button-group button:first-child').addClass('is-checked');

      // filter functions
      var filterFns = {
        // show if number is greater than 50
        numberGreaterThan50: function () {
          var number = $(this).find('.number').text();
          return parseInt(number, 100) > 500;
        },
        // show if name ends with -ium
        ium: function () {
          var name = $(this).find('.name').text();
          return name.match(/ium$/);
        }
      };
      // bind filter button click
      $('.filters-button-group').on('click', 'button', function () {
        var filterValue = $(this).attr('data-filter');
        // use filterFn if matches value
        filterValue = filterFns[filterValue] || filterValue;
        $grid.isotope({
          filter: filterValue
        });
      });
      // change is-checked class on buttons
      $('.button-group').each(function (i, buttonGroup) {
        var $buttonGroup = $(buttonGroup);
        $buttonGroup.on('click', 'button', function () {
          $buttonGroup.find('.is-checked').removeClass('is-checked');
          $(this).addClass('is-checked');
        });
      });

    });




    /*
     * Mmenu light 
     */
    var menu = new MmenuLight(
      document.querySelector('#my-menu'),
      'all'
    );
    if (menu) {
      var navigator = menu.navigation({
        // selectedClass: 'Selected',
        // slidingSubmenus: true,
        theme: 'light',
        title: 'Meni'
      });

      var drawer = menu.offcanvas({
        position: 'right'
      });

      //  Open the menu.
      document.querySelector('#nav-icon')
        .addEventListener('click', evnt => {
          evnt.preventDefault();
          drawer.open();
        });

      $(".mob-menu-close").click(function () {
        drawer.close();
      });
    }

    /*
     * Image before & after
     */

    const container = document.querySelector('.image-before-after');
    document.querySelector('.slider').addEventListener('input', (e) => {
      container.style.setProperty('--position', `${e.target.value}%`);
    })

  });
})(jQuery);